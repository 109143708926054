<template>
  <div>
    <div class="tw-relative">
      <div
        v-if="$slots['icon']"
        class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-font-medium"
      >
        <slot name="icon"></slot>
      </div>
      <input
        :class="{
          'tw-pl-10': $slots['icon'],
          'has-error': hasError,
        }"
        class="tw-frk-input"
        :autocapitalize="autoCapitalize"
        :autocomplete="autoComplete"
        :autocorrect="autoCorrect"
        :data-test="name"
        :disabled="disabled"
        :id="name"
        :max="max"
        :min="min"
        :name="name"
        :placeholder="placeholder"
        :spellcheck="spellCheck"
        :step="step"
        :type="type"
        :value="value"
        @blur="onBlur"
        @focus="onFocus"
        @input="updateValue($event.target.value)"
      />
    </div>
    <slot
      :has-focus="hasFocus"
      name="after"
    ></slot>
  </div>
</template>

<script>
export default {
  name: 'FrkInput',
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      required: false,
    },
    nullIfEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      required: false,
    },
    max: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    'auto-complete': {
      type: String,
      default: 'on',
    },
    'auto-correct': {
      type: String,
      default: 'on',
    },
    'auto-capitalize': {
      type: String,
      default: 'off',
    },
    'spell-check': {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  methods: {
    onBlur(e) {
      this.hasFocus = false;
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.hasFocus = true;
      this.$emit('focus', e);
    },
    updateValue(value) {
      let cleanedValue = value;

      if (typeof value === 'string') {
        cleanedValue = value.trim();
      }

      if (this.nullIfEmpty && typeof value === 'string' && value.length === 0) {
        cleanedValue = null;
      }

      if (this.type === 'number') {
        const floatValue = Number.parseFloat(cleanedValue);

        if (!Number.isNaN(floatValue)) {
          cleanedValue = floatValue;
        }
      }

      this.$emit('input', cleanedValue);
    },
  },
};
</script>
