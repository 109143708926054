import Vue from 'vue';
import Affix from 'vue-affix';
import VueInstantSearch from 'vue-instantsearch';
import Draggable from 'vuedraggable';
import Vuelidate from 'vuelidate';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import ClickoutDirective from '@frk/commons/ui/directives/clickout';
import AuthPlugin from '@frk/commons/ui/plugins/auth';
import Cloudinary from '@frk/commons/ui/plugins/cloudinary';
import Logger from '@frk/commons/ui/plugins/logger';
import Rudderstack from '@frk/commons/ui/plugins/rudderstack';
import Modal from '@frk/commons/ui/plugins/modal';
import FrkGooglePlaces from '@frk/commons/ui/plugins/places';
import SentryPlugin from '@frk/commons/ui/plugins/sentry';
import VTooltipPlugin from '@frk/commons/ui/plugins/v-tooltip';
import MetaPlugin from '@frk/commons/ui/plugins/vue-meta';

import { createApolloClient } from '@frk/commons/api/utils/apolloClient';
import { initSentryBrowser } from '@frk/commons/api/utils/sentryClient';
import { VueApollo } from '@frk/commons/ui/utils/vueApollo';
import pkg from '@frk/version/package.json';

import './assets/styles/tailwind.css';

import router from './router';
import App from './ui/App.vue';
import AlgoliaPlugin from './ui/plugins/algolia';
import store from './ui/store';

const {
  NODE_ENV,
  VUE_APP_AUTH0_AUDIENCE,
  VUE_APP_AUTH0_DOMAIN,
  VUE_APP_AUTH0_ID,
  VUE_APP_API_URL,
  VUE_APP_CONFIG_ENV,
  VUE_APP_GOOGLE_API_KEY,
  VUE_APP_SENTRY_DSN,
} = process.env;

const sentry = initSentryBrowser({
  project: 'ui-admin',
  version: pkg.version,
  environment: VUE_APP_CONFIG_ENV,
  dsn: VUE_APP_SENTRY_DSN,
  Vue,
});

export const apolloClient = createApolloClient({
  apiUri: VUE_APP_API_URL,
  NODE_ENV,
  sentry,
});

Vue.config.productionTip = false;

Vue.component('Draggable', Draggable);

Vue.directive('clickout', ClickoutDirective);

Vue.use(Affix);
Vue.use(AlgoliaPlugin, { apolloClient });
Vue.use(Cloudinary);
Vue.use(Donut);
Vue.use(Logger);
Vue.use(MetaPlugin);
Vue.use(Modal, { Vue });
Vue.use(Rudderstack);
Vue.use(SentryPlugin, { sentry });
Vue.use(VTooltipPlugin);
Vue.use(VueApollo);
Vue.use(VueInstantSearch);
Vue.use(Vuelidate);

Vue.use(FrkGooglePlaces, {
  apiKey: VUE_APP_GOOGLE_API_KEY,
});

Vue.use(AuthPlugin, {
  apolloClient,
  auth0ClientOptions: { audience: VUE_APP_AUTH0_AUDIENCE, domain: VUE_APP_AUTH0_DOMAIN, client_id: VUE_APP_AUTH0_ID },
  defaultRedirectUri: process.env.VUE_APP_BASE_URL,
  logoutReturnTo: process.env.VUE_APP_BASE_URL,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

new Vue({
  router,
  store,
  apolloProvider: new VueApollo({
    defaultClient: apolloClient,
  }),
  render: h => h(App),
}).$mount('#app');
