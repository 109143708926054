"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'GenericButton',
    props: {
        color: null,
        disabled: { type: Boolean },
        fluid: { type: Boolean },
        hasArrow: { type: Boolean },
        icon: { type: Boolean },
        outline: { type: Boolean },
        size: null,
        text: { type: Boolean },
        noShadow: { type: Boolean }
    },
    emits: ["click"],
    setup(__props, { emit }) {
        const props = __props;
        const button = (0, vue_2.ref)(null);
        const computedClass = (0, vue_2.computed)(() => {
            const cclass = [];
            if (props.text) {
                cclass.push('btn-text');
            }
            else if (props.icon) {
                cclass.push('btn-icon');
            }
            else {
                cclass.push('btn');
            }
            if (props.hasArrow && !props.icon) {
                cclass.push('btn-has-arrow');
            }
            if (props.fluid && !(props.text || props.icon)) {
                cclass.push('btn-fluid');
            }
            if (props.disabled) {
                cclass.push('btn-disabled');
            }
            if (props.outline && !props.text) {
                cclass.push('btn-outline');
            }
            if (props.color) {
                cclass.push(`btn-${props.color}`);
            }
            if (props.noShadow) {
                cclass.push('btn-no-shadow');
            }
            // Size
            cclass.push(`btn-${props.size}`);
            return cclass;
        });
        function clicked(event) {
            if (!props.disabled) {
                // Propagates the DOM native event to allow parent to eventually apply modifiers on it (e.g. 'stop', 'prevent', 'seft')
                emit('click', event);
                button.value?.focus();
            }
        }
        return { __sfc: true, button, props, emit, computedClass, clicked };
    }
});
