"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIAL_PERIOD_UNIT_OPTIONS = exports.NOTICE_PERIOD_UNIT_OPTIONS = exports.INITIAL_PERIOD_STAKEHOLDERS_OPTIONS = exports.PAYMENT_PERIOD_OPTIONS = exports.CLIENT_STATUS_OPTIONS = void 0;
const graphql_types_1 = require("@frk/graphql-types");
exports.CLIENT_STATUS_OPTIONS = [
    { value: graphql_types_1.ClientStatus.Prospect, label: 'Prospect', color: 'black' },
    { value: graphql_types_1.ClientStatus.Ok, label: 'Validé', color: 'green' },
    { value: graphql_types_1.ClientStatus.Ko, label: 'Refusé', color: 'red' },
];
exports.PAYMENT_PERIOD_OPTIONS = [
    { value: graphql_types_1.PaymentPeriodEnum.UponReceipt, label: 'à réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.EndOfMonth, label: 'fin de mois' },
    { value: graphql_types_1.PaymentPeriodEnum.FifteenDays, label: 'à 15 jours date de réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.TwentyDays, label: 'à 20 jours date de réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.ThirtyDays, label: 'à 30 jours date de réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.ThirtyDaysEom, label: 'à 30 jours fin de mois' },
    { value: graphql_types_1.PaymentPeriodEnum.ThirtyDaysEomTenth, label: 'à 30 jours fin de mois le 10' },
    { value: graphql_types_1.PaymentPeriodEnum.FortyfiveDays, label: 'à 45 jours date de réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.FortyfiveDaysEom, label: 'à 45 jours fin de mois' },
    { value: graphql_types_1.PaymentPeriodEnum.FortyfiveDaysEomTenth, label: 'à 45 jours fin de mois le 10' },
    { value: graphql_types_1.PaymentPeriodEnum.SixtyDays, label: 'à 60 jours date de réception de facture' },
    { value: graphql_types_1.PaymentPeriodEnum.SixtyDaysEom, label: 'à 60 jours fin de mois' },
    { value: graphql_types_1.PaymentPeriodEnum.SixtyDaysEomTenth, label: 'à 60 jours fin de mois le 10' },
];
exports.INITIAL_PERIOD_STAKEHOLDERS_OPTIONS = [
    { value: graphql_types_1.ClientInitialPeriodStakeholdersEnum.None, label: 'Aucune' },
    { value: graphql_types_1.ClientInitialPeriodStakeholdersEnum.Frk, label: 'Pour FRK' },
    { value: graphql_types_1.ClientInitialPeriodStakeholdersEnum.FrkFreelance, label: 'Pour FRK & Prestataire' },
];
// TODO Someday generate options (and custom labels with pluralization) from graphql @directives
exports.NOTICE_PERIOD_UNIT_OPTIONS = [
    {
        value: graphql_types_1.ClientNoticePeriodUnitEnum.Days,
        label: ({ value }) => (value > 1 ? 'jours calendaires' : 'jour calendaire'),
    },
    { value: graphql_types_1.ClientNoticePeriodUnitEnum.BusinessDays, label: ({ value }) => (value > 1 ? 'jours ouvrés' : 'jour ouvré') },
    { value: graphql_types_1.ClientNoticePeriodUnitEnum.Month, label: () => 'mois' },
    { value: graphql_types_1.ClientNoticePeriodUnitEnum.MonthEom, label: () => 'mois, à partir de la fin du mois en cours' },
];
exports.INITIAL_PERIOD_UNIT_OPTIONS = [
    {
        value: graphql_types_1.ClientInitialPeriodUnitEnum.Days,
        label: ({ value }) => (value > 1 ? 'jours calendaires' : 'jour calendaire'),
    },
    {
        value: graphql_types_1.ClientInitialPeriodUnitEnum.BusinessDays,
        label: ({ value }) => (value > 1 ? 'jours ouvrés' : 'jour ouvré'),
    },
];
