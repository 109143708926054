import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import { withFieldNames } from '.';
import { COMPANY_FORM_OPTIONS } from '../../../api/models/Company.core';
import FrkAmountInput from '../../components/FrkAmountInput.vue';
import FrkInput from '../../components/FrkInput.vue';
import FrkPlacesInput from '../../components/FrkPlacesInput.vue';
import FrkSelect from '../../components/FrkSelect.vue';

const fieldDefs = {
  name: {
    label: "Dénomination sociale de l'entreprise",
    component: FrkInput,
    validations: {
      required,
    },
  },
  siret: {
    label: 'SIRET',
    validations: {
      minLength: minLength(14),
      maxLength: maxLength(14),
    },
    component: FrkInput,
  },
  activity: {
    label: 'Activité Principale',
    component: FrkInput,
  },
  rcs: {
    label: 'Lieu immatriculation RCS',
    component: FrkInput,
  },
  form: { label: 'Forme Juridique', component: FrkInput },
  tva: {
    label: 'N° TVA intracommunautaire',
    component: FrkInput,
  },
  capital: {
    label: 'Capital Social',
    component: FrkAmountInput,
    props: {
      step: 0.01,
    },
  },
  address: {
    label: 'Adresse du siège social',
    component: FrkPlacesInput,
  },
};

// TODO Enforce proper type when migrating index.ts to typescript
type CompanyFormFieldFreelanceExtension = {
  props: {
    options: typeof COMPANY_FORM_OPTIONS;
  };
  component: typeof FrkSelect;
};

type Fields = any;
export const fields: Fields = withFieldNames({
  fieldDefs,
  inputRoot: 'company',
});

export const companyFormFieldFreelanceExtension: CompanyFormFieldFreelanceExtension = {
  props: {
    options: COMPANY_FORM_OPTIONS,
  },
  component: FrkSelect,
};
