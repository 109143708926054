"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'FrkButton',
    props: {
        color: { default: null },
        full: { type: Boolean, default: false },
        href: null,
        to: null,
        target: null,
        small: { type: Boolean, default: false },
        type: { default: 'button' }
    },
    emits: ['click'],
    setup(__props, { emit }) {
        const props = __props;
        const computedClass = (0, vue_2.computed)(() => {
            const classes = [];
            if (props.full) {
                classes.push('tw-w-full');
            }
            if (props.small) {
                classes.push('tw-py-2.5');
            }
            else {
                classes.push('tw-py-3');
            }
            switch (props.color) {
                case 'primary':
                    classes.push('tw-border-frkblue', 'tw-bg-frkblue', 'tw-text-white', 'dark:tw-border-gray-700', 'dark:tw-bg-slate-900', 'dark:hover:tw-bg-gray-800', 'dark:focus:tw-ring-gray-600');
                    break;
                case 'secondary':
                    classes.push('tw-border-frkblue-dark', 'tw-bg-frkblue-dark', 'tw-text-white', 'dark:tw-border-gray-700', 'dark:tw-bg-slate-900', 'dark:hover:tw-bg-gray-800', 'dark:focus:tw-ring-gray-600');
                    break;
                case 'danger':
                    classes.push('tw-border-red-600', 'tw-bg-red-600', 'tw-text-white', 'dark:tw-border-red-500', 'dark:tw-bg-red-500', 'dark:hover:tw-bg-red-400', 'dark:focus:tw-ring-red-400');
                    break;
                default:
                    classes.push('tw-border-gray-200', 'tw-bg-white', 'tw-text-gray-500', 'hover:tw-bg-gray-100', 'dark:tw-border-gray-700', 'dark:tw-text-gray-400', 'dark:hover:tw-bg-gray-700', 'dark:focus:tw-outline-none', 'dark:focus:tw-ring-1', 'dark:focus:tw-ring-gray-600');
                    break;
            }
            return classes;
        });
        return { __sfc: true, emit, props, computedClass };
    }
});
