<template>
  <form
    class="tw-font-circular"
    :class="{ 'has-idle-fields': hasIdleFields }"
    :data-state="formState.value"
    :data-test="`${name}--FrkFormGenerator`"
    @submit.prevent="autoformService.send('SAVE')"
  >
    <div
      v-if="absoluteEditFlow"
      class="tw-absolute tw-right-4 tw-top-4"
    >
      <FrkEditFlow
        :any-error="$v.formContext.$anyError"
        :in-edition="formState.value === 'edit'"
        :is-loading="formState.value === 'loading'"
        @cancel="autoformService.send({ type: 'CANCEL', apiData })"
        @edit="autoformService.send('EDIT')"
        @save="autoformService.send('SAVE')"
        data-test="EditFlow"
        v-show="!hideEditFlow"
        right
      />
    </div>
    <div
      class="tw-mb-4"
      v-if="$scopedSlots['top']"
    >
      <slot
        :form-state="formState"
        :validated-form-context="$v.formContext"
        name="top"
      ></slot>
    </div>
    <div
      class="tw-mb-4"
      v-if="$v.formContext.$invalid"
    >
      <FrkFormValidationErrors
        :messages="messages"
        :validator="$v.formContext"
      />
    </div>
    <div class="form__body tw-flex tw-flex-col tw-items-start tw-gap-4 md:tw-flex-row">
      <slot
        :form-state="formState"
        :validated-form-context="$v.formContext"
        name="left"
      ></slot>
      <!-- Idle Form -->
      <div
        class="tw-w-full"
        v-if="formState.value === 'idle' && idleFields"
      >
        <div
          class="tw-mb-4 tw-flex tw-flex-col tw-gap-4 last:tw-mb-0 md:tw-flex-row"
          :key="rowIndex"
          v-for="(row, rowIndex) in idleFields"
        >
          <div
            class="idleform__field tw-flex-1"
            :key="fieldIndex"
            v-for="(field, fieldIndex) in row"
          >
            <Component
              :is="field.component"
              :name="`${name}--${field.name}`"
              :value="getFieldValueFrom(apiData, field)"
              v-bind="getFieldProps(field)"
            />
          </div>
        </div>
      </div>
      <!-- Edition Form -->
      <div
        class="tw-w-full"
        v-else
      >
        <div
          class="form__row tw-mb-4 tw-flex tw-flex-col tw-gap-4 last:tw-mb-0 md:tw-flex-row"
          :key="rowIndex"
          v-for="(row, rowIndex) in fields"
        >
          <div
            class="form__col tw-flex-1"
            :key="fieldIndex"
            v-for="(field, fieldIndex) in row"
            v-show="typeof field.showIf === 'function' ? field.showIf({ apiData, formContext }) : true"
          >
            <div
              class="tw-flex tw-items-start tw-gap-2"
              v-if="field.idle ? formState.value !== 'idle' : true"
            >
              <label
                class="tw-mb-2 tw-block tw-text-sm tw-font-medium tw-text-gray-800 dark:tw-text-white"
                :class="{
                  'has-error': getFieldValueFrom($v.formContext, field).$error,
                  'is-required': isFieldRequired(field),
                }"
                :for="`${name}--${field.name}`"
              >
                <Component
                  :is="field.labelComponent"
                  :label="getFieldLabel(field)"
                  v-if="field.labelComponent"
                /><span v-else>{{ getFieldLabel(field) }}</span>
              </label>
              <FrkHelpIcon v-if="getFieldHelpText(field)">
                <template #help-text>
                  <span>{{ getFieldHelpText(field) }}</span>
                </template>
              </FrkHelpIcon>
            </div>
            <Component
              :disabled="formState.value !== 'edit' || getFieldProps(field).disabled"
              :form-context="formContext"
              :has-error="getFieldValueFrom($v.formContext, field).$error"
              :is="field.idle && formState.value === 'idle' ? field.idle.component : field.component"
              :name="`${name}--${field.name}`"
              :value="getFieldValueFrom($v.formContext, field).$model"
              @input="updateFieldValue($event, field)"
              size="fluid"
              v-bind="getFieldProps(field)"
            />
          </div>
        </div>
      </div>
      <FrkEditFlow
        v-if="!absoluteEditFlow"
        class="tw-flex-none"
        :any-error="$v.formContext.$anyError"
        :in-edition="formState.value === 'edit'"
        :is-loading="formState.value === 'loading'"
        @cancel="autoformService.send({ type: 'CANCEL', apiData })"
        @edit="autoformService.send('EDIT')"
        @save="autoformService.send('SAVE')"
        data-test="EditFlow"
        v-show="!hideEditFlow"
        right
      />
      <div v-if="$scopedSlots['action']">
        <slot
          :form-state="formState"
          :validated-form-context="$v.formContext"
          name="action"
        ></slot>
      </div>
    </div>
    <div v-if="$scopedSlots['bottom']">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <FrkAlert
          v-for="help in help"
          :key="help.text"
          :emoji="help.emoji"
          :text="help.text"
        />
      </div>
      <FrkDivider />
      <slot
        :form-state="formState"
        :machine="autoformService"
        :validated-form-context="$v.formContext"
        name="bottom"
      >
      </slot>
    </div>
  </form>
</template>

<script>
import { isDefined } from '@frk/helpers';
import debounce from 'lodash.debounce';
import objectPath from 'object-path';
import { mapActions } from 'vuex';
import { assign, createMachine, interpret } from 'xstate';

import { label } from '../../api/formValidators';

import FrkFormValidationErrors from './FrkFormValidationErrors.vue';
import GenericButton from './GenericButton.vue';
import FrkEditFlow from './FrkEditFlow.vue';
import FrkHelpIcon from './FrkHelpIcon.vue';
import FrkDivider from './FrkDivider.vue';
import FrkAlert from './FrkAlert.vue';

function createAutoformMachine({ initial, context, actions, guards }) {
  return createMachine(
    {
      id: 'autoform',
      initial,
      context,
      states: {
        idle: {
          entry: actions.resetValidations,
          on: {
            EDIT: 'edit',
            NEW_API_DATA_INCOMING: {
              actions: actions.resetToApiData,
            },
          },
        },
        edit: {
          on: {
            SAVE: {
              target: 'loading',
              cond: guards.validFormContext,
            },
            CANCEL: {
              target: 'idle',
              actions: [actions.resetToApiData, actions.propagateEvent],
            },
            NEW_API_DATA_INCOMING: {
              actions: actions.resetToApiData,
            },
          },
        },
        loading: {
          entry: actions.saveForm,
          on: {
            SUCCESS: {
              target: 'idle',
              actions: actions.propagateEvent,
            },
            ERROR: {
              target: 'edit',
              actions: actions.propagateEvent,
            },
            NEW_API_DATA_INCOMING: {
              actions: actions.resetToApiData,
            },
          },
        },
      },
    },
    {
      actions,
      guards,
    }
  );
}

function getFieldsDefs(fields) {
  return fields.reduce((flatList, row) => [...flatList, ...row], []);
}

// Create a formContext object mapping each fields to a given value
// Example use cases:
// - create an empty form context with each field set to null if no api data provided
// - create a "mirror" vuelidate structure to the form context with validations properties
// From [ [{name: 'abc'}, {name: 'xyz'}], [{name: 'foo.bar'}, {name: 'foo.buzz'}] ]
// Creates { abc: ..., xyz: ..., foo: { bar: ..., buzz: ... } }
function createFormContext({ fields, makeFieldValue }) {
  const fieldsDefs = getFieldsDefs(fields);

  return fieldsDefs.reduce((formContext, field) => {
    const fieldValue = makeFieldValue(field);

    objectPath.set(formContext, field.name, fieldValue);

    return formContext;
  }, {});
}

function mutateFormContextWithComputedValues({ fields, formContext, apiData, dependency }) {
  // Update any related fields if necessary
  const fieldsDefs = getFieldsDefs(fields);

  fieldsDefs
    .filter(field => {
      // filter field without computed value definition
      if (!field.computedValue) {
        return false;
      }

      // some fields are conditionally computed, filter the ones that fail the condition
      if (typeof field.computedValue.shouldCompute === 'function') {
        const shouldCompute = field.computedValue.shouldCompute({ apiData, formContext });

        if (!shouldCompute) {
          return false;
        }
      }

      // if a dependency (field name) is specified, run only computation that depends on that field
      if (dependency) {
        return field.computedValue.dependencies.includes(dependency);
      }

      return true;
    })
    .forEach(field => {
      const computedValue = field.computedValue.updater({
        apiData,
        formContext,
      });

      // Only set a computed value if it has been computed!
      if (isDefined(computedValue)) {
        objectPath.set(formContext, field.name, computedValue);
      }
    });
}

export default {
  name: 'FrkFormGenerator',
  components: {
    FrkFormValidationErrors,
    GenericButton,
    FrkEditFlow,
    FrkHelpIcon,
    FrkDivider,
    FrkAlert,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    help: {
      type: Array,
      default: () => [],
    },
    apiData: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Array,
    },
    // Replace the prop 'fields' on state 'idle'.
    idleFields: {
      type: Array,
      optional: true,
      default: () => undefined,
    },
    validationContext: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    initialState: {
      default: 'idle',
    },
    messages: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    autoSaveDelay: {
      type: Boolean,
      optional: true,
      default: () => false,
    },
    hideEditFlow: {
      type: Boolean,
      optional: true,
      default: () => false,
    },
    absoluteEditFlow: {
      type: Boolean,
      optional: true,
      default: () => false,
    },
  },
  data() {
    // Pseudo-type validation before switching to TypeScript
    // as "null" passes all validation on a non-required prop
    if (this.apiData === null) {
      throw new Error('[FrkFormGenerator] apiData should be undefined or an object. Received "null"');
    }

    // const propagateEvent = eventName => () => this.$emit(eventName)

    const machine = createAutoformMachine({
      initial: this.initialState,
      context: this.createInitialContext(this.apiData),
      actions: {
        resetValidations: () => this.$v.formContext.$reset(),
        resetToApiData: assign((context, event) => this.createInitialContext(event.apiData)),
        propagateEvent: (context, event) => this.$emit(event.type.toLowerCase()),
        saveForm: context => this.saveForm(context),
      },
      guards: {
        validFormContext: () => this.isFormValid(),
      },
    });

    return {
      // Interpret the machine and store it in data
      autoformService: interpret(machine),
      // Start with the machine's initial state
      formState: machine.initialState,
      // Start with the machine's initial context
      formContext: machine.context,
    };
  },
  computed: {
    hasIdleFields() {
      const fieldsDefs = getFieldsDefs(this.fields);
      return !!this.idleFields || !!Object.values(fieldsDefs).find(field => field.idle);
    },
  },
  watch: {
    apiData: {
      deep: true,
      handler(newApiData) {
        this.autoformService.send({
          type: 'NEW_API_DATA_INCOMING',
          apiData: newApiData,
        });
      },
    },
  },
  created() {
    // Start service on component creation
    this.autoformService
      .onTransition(state => {
        // Update the current state component data property with the next state
        this.formState = state;
        // Update the context component data property with the updated context
        this.formContext = state.context;
        // Store the form state
        this.storeFormState({ formName: this.name, state });
      })
      .start();
    this.debouncedAutoSave = debounce(() => this.autoformService.send('SAVE'), 1000);
  },
  beforeDestroy() {
    this.deleteFormState(this.name);
    if (this.debouncedAutoSave) {
      this.debouncedAutoSave.cancel();
    }
  },
  methods: {
    ...mapActions('forms', ['deleteFormState', 'storeFormState']),
    ...mapActions('messages', ['pushFlashMessage']),
    // Reusable context factory used for initial render and when api data changes
    // If the form has been edited and the API data changes, the formContext will be set
    createInitialContext(apiData) {
      const initialContext = createFormContext({
        fields: this.fields,
        makeFieldValue: field => {
          try {
            if (field.initialValue) {
              return field.initialValue({ apiData, formContext: this.formContext });
            }

            // this function call is wrapped by a try/catch
            // in case a nested field is tried to be reached
            // on an apiData object not featuring the root field
            // ex: trying to access 'company.name' on an empty object {} would fail
            // => { company: { name: null } }
            const fieldValue = this.getFieldValueFrom(apiData, field, null);
            return fieldValue;
          } catch (_) {
            return null;
          }
        },
      });

      mutateFormContextWithComputedValues({ formContext: initialContext, apiData, fields: this.fields });

      return initialContext;
    },
    async updateFieldValue(newValue, field) {
      // Update the field value in the form context
      objectPath.set(this.formContext, field.name, newValue);

      // Run validation for the field
      const vuelidateField = objectPath.get(this.$v.formContext, field.name);
      vuelidateField.$touch();

      // Mutate the form context based on a new field value if necessary
      if (field.sideEffect) {
        await field.sideEffect({
          newValue,
          formContext: this.formContext,
          $apollo: this.$apollo,
        });
      }

      mutateFormContextWithComputedValues({
        fields: this.fields,
        apiData: this.apiData,
        formContext: this.formContext,
        dependency: field.name,
      });

      this.$emit('context-change', this.formContext, this.autoformService);

      if (this.autoSaveDelay) {
        this.debouncedAutoSave();
      }
    },
    getFieldHelpText(field) {
      return typeof field.helpText === 'function'
        ? field.helpText({ apiData: this.apiData, formContext: this.formContext })
        : field.helpText;
    },
    getFieldLabel(field) {
      return typeof field.label === 'function'
        ? field.label({ apiData: this.apiData, formContext: this.formContext })
        : field.label;
    },
    getFieldProps(field) {
      if (field.idle && field.idle.props && this.formState.value === 'idle') {
        return field.idle.props({ apiData: this.apiData });
      }

      return typeof field.props === 'function'
        ? field.props({ apiData: this.apiData, formContext: this.formContext })
        : field.props || {};
    },
    getFieldValueFrom(context, field, defaultValue = undefined) {
      const fieldValue = objectPath.get(context, field.name);

      return fieldValue === undefined ? defaultValue : fieldValue;
    },
    isFieldRequired(field) {
      const requiredParam = this.getFieldValueFrom(this.$v.formContext, field).$params.required;

      return (
        requiredParam?.type === 'required' ||
        (requiredParam?.type === 'requiredIf' && requiredParam?.prop(this.formContext))
      );
    },
    isFormValid() {
      // Run zynchronous validation on each fields
      this.$v.$touch();

      // the form is valid if there is not any validation error
      return !this.$v.$anyError;
    },
    saveForm(formData) {
      this.$emit('save', {
        formData,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: (error, callback = () => {}) => {
          this.pushFlashMessage({
            message: error.message,
            colorClass: 'invalid',
          });
          this.autoformService.send('ERROR');

          // make it possible to operate on the machine
          callback(this.autoformService);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSuccess: (msg, callback = () => {}) => {
          if (msg !== null) {
            this.pushFlashMessage({
              message: msg || 'Informations mises à jour !',
            });
          }

          this.autoformService.send('SUCCESS');

          // make it possible to operate on the machine
          callback(this.autoformService);
        },
      });
    },
  },
  validations() {
    return {
      formContext: createFormContext({
        fields: this.fields,
        makeFieldValue: field => {
          const fieldValidations =
            typeof field.validations === 'function'
              ? field.validations({
                  ...(this.validationContext || {}),
                  loggedUser: this.$auth.loggedUser,
                  // ! Black magic
                  formContext: this.formContext,
                })
              : field.validations;

          return {
            ...(fieldValidations || {}),
            label: label(this.getFieldLabel(field)),
          };
        },
      }),
    };
  },
};
</script>

<style lang="scss" scoped>
.is-required {
  &:after {
    content: '*';
    @apply tw-ml-1 tw-text-red-400;
  }
}
</style>
